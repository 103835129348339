<template>

  <div class="tabs">

    <div style="background: #409eff;color:white;height:2rem;border-radius: 5px 5px 0 0;line-height: 2rem;padding-left: 1rem">场景导航</div>
    <el-col :span="24" v-for="(url, index) in urls" :key="index" style="background:#ffffff">
      <el-card :body-style="{ padding:'0px',background:'#fff',borderRadius: '0' }"  >
        <div :class="{active:selected==index}"  style="height: 3rem;width:12rem;margin: 0.5rem 1rem" @click="change(url,index)">

          <img :src="url.src" class="image" >
          <span style="display: inline-block;position: relative;left: 1rem;top:0.2rem;font-size: 12px">{{url.room}}</span>
        </div>
      </el-card>
    </el-col>
  </div>

</template>

<script>
export default {
  data() {
    return {
      visible: true,
      selected:0,
      
    };
  },
  props: {
    urls: [],

  },
  methods:{
    change(url,index){
      this.$emit('aa',url);
      this.selected = index
      this.$parent.$refs.audio3.pause()

      if(this.selected==0&&(this.$parent.turn||this.$parent.move)&&(this.$parent.activeKey==0||this.$parent.activeKey==2)){
        this.$parent.play3('12-1-5主机风机运行反馈.mp3');
       
      }
      if(this.selected==1&&(this.$parent.turn||this.$parent.move)){
        this.$parent.play3('12-1-4风机启动声.mp3');
      }
      
      if(this.selected==1&&this.$parent.turn&&(this.$parent.activeKey==1||this.$parent.activeKey==3)){
        this.$parent.play3('12-1-4风机启动声.mp3');
      }
      if(this.selected==0&&this.$parent.move&&(this.$parent.activeKey==1||this.$parent.activeKey==3)){
        this.$parent.play3('12-1-5主机风机运行反馈.mp3');
      }
      
    }
  }


};

</script>

<style lang="less" scoped>
.tab-img {
  margin-bottom: 0.25rem;
  border-radius: 0.3125rem;
  border: 0.3125rem solid #ccc;
  width:2.5rem;
}
.tabs{
  width: 15rem;
//  height:10.5rem;
  position: absolute;
  bottom: 10rem;
  right:8rem;
  overflow-y: auto;

}
.image{
  height:2.8rem;
  border-radius:5px;

}
.active{
  border: 1px solid #409eff;border-radius:5px;
}

</style>