<template>
  <el-dialog :visible="visible" :showClose="false" :modal="false" lock-scroll>
      <!--8 压力表 -->
    <div class="pressguage">
      <img src="../assets/img/zoomOut.png" class="zoomOut" @click="onClose" />
      <img
        src="../assets/img/4-8-4-1.png"
        style="width: 40rem"
      />
      <div id="gauge"></div>
    </div>
  </el-dialog>
</template>

<script>
import * as echarts from 'echarts';
export default {
  props: {
    visible: {
      type: Boolean,
      default: true,
    },
    echartsData:{
      type:[String,Number],
      default:0.8
    },
    close: {
      type: Function,
    },
  },
  watch:{
    visible(val){
      if(val){
        this.$nextTick(() => {
          this.initEcharts()
        })
      }
    }
  },
  methods:{
    onClose(){
      this.close && this.close()
    },
    initEcharts(){
      var myChart = echarts.init(document.getElementById('gauge'));
      let option = {
        title: [
          {
            text: '压力表',
            top: '35%',
            left: 'center',
            textStyle:{
              fontSize:12,
              fontWeight:10
            }
          },
          {
              text: 'Mpa 2.5级',
              top: '56%',
              left: 'center',
              textStyle:{
                fontSize:12,
                fontWeight:10
              }
          }
        ],
        series: [
          {
            name: 'Pressure',
            type: 'gauge',
            min:0,
            max:1.6,
            splitNumber:4,
            axisLine: {
              show: false, // 不显示外圈 
            },
            detail:{
              show:false
            },
            title: {
              show: false,
            },
            data: [
              {
                value: this.echartsData,
                name: 'Mpa 2.5级'
              }
            ]
          }
        ]
      }
      myChart.setOption(option);
    }
  }
}
</script>

<style lang="less" scoped>
.el-dialog__wrapper {
  position: absolute;
}
/deep/.el-dialog {
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  margin: 0 !important;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  max-height: calc(100% - 1.875rem);
  max-width: calc(100% - 1.875rem);
  background: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  width: auto;
}
/deep/.el-dialog .el-dialog__body {
  max-height: 100%;
  flex: 1;
  -ms-flex: 1 1 auto;
  overflow-y: hidden;
  overflow-x: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

// 压力表  
.pressguage {
  background-color: #fff;
  position: relative;
  border: 8px solid #fff;
  border-radius: 16px;
  img {
    border-radius: 16px;
  }
  .zoomOut {
    width: 2.8125rem;
    height: 2.8125rem;
    cursor: pointer;
    position: absolute;
    right: 1.25rem;
    top: 1.25rem;
  }
  #gauge {
    position: absolute;
    top: 6.3%;
    left: 26.8%;
    width: 260px; 
    height: 260px;
    border-radius: 50%;
  }
}


</style>