<template>
  <el-dialog :visible="visible" :showClose="false" :modal="false" lock-scroll style="position: absolute">
    <!-- 内容-->
    <div data-v-0b296e9a="" class="stable" scence-idx="0">
      <img src="../assets/img/zoomOut.png" class="zoomOut" @click="onClose" >
      <div class="lights">
        <div class="light"><span>电源指示</span><span :class="{red:first==1}"></span></div>
        <div class="light"><span>1泵运行指示</span><span class="grey" :class="{green:run}" ></span></div>
        <div class="light"><span>1泵故障指示</span><span class="grey" :class="{yellow:water}"></span></div>
        <div class="light"><span>2泵运行指示</span><span class="grey" :class="{green:water}"></span></div>
        <div class="light"><span>2泵故障指示</span><span class="grey"></span></div>
      </div>
      <div class="buttons">
        <div class="button state">
          <div style="width: 5rem;">
            <span>手动</span><span>自动</span>
          </div>
          <span class="b" @click="onSwitch">
            <span class="brotate" :class="{auto:auto}"></span>
          </span>
        </div>
        <div class="button green">
          <div>启动</div>
          <span class="b" @click="on"></span>
        </div>
        <div class="button red">
          <div>停止</div>
          <span class="b"@click="off"></span>
        </div>
        <div class="button state">
          <div>
            <span>1主2备</span><span>2主1备</span>
          </div>
          <span class="b">
            <span class="brotate"></span>
          </span>
        </div>
      </div>
      <div class="label"> 稳压泵控制柜 </div>
    </div>
  </el-dialog>
</template>

<script>

export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    first: null,
    move: {
      type: Boolean,
      default: false,
    },
    water: {
      type: Boolean,
      default: false,
    },
    keywordOption: {
      type: Object,
      default: () => { },
    },
    close: {
      type: Function,
    },
  },
  watch: {

  },
  data() {
    return {
      keyCode: "f6",
      isShow: false,
      title: '火警信息',
      trans: false,
      run: false,
      auto:false
    };
  },
  created() {


  },
  methods: {
    show() {
      this.isShow = true;
    },
    on() {
      if(!this.auto) {
        this.run = true;
      }
      
    },
    off() {
      if(!this.auto) {
        this.run = false;
      }

      

    },
    onSwitch() {
      
        this.auto = !this.auto;
        this.$parent.play2('9-1多线盘旋手自动转换旋钮开关.mp3')
      

    },

    onDown(parms) {
      if (!this.keywordOption[parms]) return
      this.keyCode = parms;
      this.title = this.keywordOption[parms]['title']
    },
    onClose() {
      this.close && this.close();
      this.$parent.play3('zoomin.mp3')
    },
  },
};
</script>

<style lang="less" scoped>
.stable .lights,
.stable {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.stable .buttons .button.state .b .brotate.auto {
    -webkit-transform: rotate(30deg);
    transform: rotate(30deg);
}
.stable .lights .light span:last-child.yellow{
    background-color: #fff700;
    -webkit-box-shadow: 0 0 .5rem #fff700;
    box-shadow: 0 0 .5rem #fff700;
}
.stable .lights .light span:last-child.green{
    background-color: #a0ffa5;
    -webkit-box-shadow: 0 0 .5rem #a0ffa5;
    box-shadow: 0 0 .5rem #a0ffa5;
}
.water-level .level {
    position: absolute;
    width: 1rem;
    height: 0;
    left: 9.8571rem;
    bottom: 2.7143rem;
    background: rgba(0, 0, 0, .2);
}


.stable {
  scale: .9;
  border: .5rem solid #fff;
  border-radius: 1.25rem;
  background-color: #cfced4;
  position: relative;
  width: 33.25rem;
  height: 47.0625rem;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 1.875rem 1.25rem;
  color: #000;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

img {
  border-style: none;
}

.stable .lights {
  margin-top: 5rem;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
}

.stable .zoomOut {
  width: 2.8125rem;
  height: 2.8125rem;
  cursor: pointer;
  position: absolute;
  right: 1.25rem;
  top: 1.25rem;
}

.stable .lights,
.stable {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.stable .lights .light {
  width: 20%;
  font-weight: 600;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: .9375rem;
}

.stable .lights .light span:last-child.red {
  background-color: #ffa39c;
  -webkit-box-shadow: 0 0 0.5rem #ffa39c;
  box-shadow: 0 0 0.5rem #ffa39c;
}

.stable .lights .light span:last-child {
  width: 2.5rem;
  height: 2.5rem;
  background-color: #e4e4e7;
  margin-top: .9375rem;
  border-radius: 2.5rem;
  -webkit-box-shadow: 0.0625rem 0.125rem 0.3125rem #888;
  box-shadow: 0.0625rem 0.125rem 0.3125rem #888;
}
.stable .buttons{
    margin-top: 1.875rem;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width: 100%;
    padding: 0 1.875rem;
}
.stable .buttons .button.state .b .brotate {
    height: 1.875rem;
    width: .375rem;
    background-color: #fff;
    position: absolute;
    display: inline-block;
    left: 50%;
    margin-left: -.1875rem;
    top: 50%;
    margin-top: -1.875rem;
    -webkit-transform: rotate(-30deg);
    transform: rotate(-30deg);
    -webkit-transform-origin: bottom center;
    transform-origin: bottom center;
    -webkit-transition: -webkit-transform 1s;
    transition: -webkit-transform 1s;
    transition: transform 1s;
    transition: transform 1s, -webkit-transform 1s;
    border-radius: .1875rem;
    -webkit-box-shadow: 0.0625rem 0.0625rem 0.0625rem #333;
    box-shadow: 0.0625rem 0.0625rem 0.0625rem #333;
}
.stable .buttons .button div {
    width: 6.25rem;
    height: 1.875rem;
    display: inline-block;
    line-height: 1.875rem;
    font-weight: 600;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    text-align: center;
    font-size: .75rem;
}
.stable .buttons .button.green div, .stable .buttons .button.red div {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.stable .buttons .button,
.stable .buttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.stable .buttons .button.state {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.stable .buttons .button {
  width: 25%;
  font-weight: 600;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  font-size: .9375rem;
}

.stable .buttons .button.state .b {
  background-color: #3e3e3e;
  position: relative;
}

.stable .buttons .button .b {
  display: inline-block;
  width: 3rem;
  height: 3rem;
  border-radius: 3rem;
  -webkit-box-shadow: inset 0.125rem 0.125rem 0.0625rem #fff, 0.0625rem 0.0625rem 0.0625rem #000, inset -0.0625rem -0.0625rem 0.0625rem #000;
  box-shadow: inset 0.125rem 0.125rem 0.0625rem #fff, 0.0625rem 0.0625rem 0.0625rem #000, inset -0.0625rem -0.0625rem 0.0625rem #000;
  margin-top: .625rem;
  position: relative;
  cursor: pointer;
}

.stable .buttons .button.green .b {
  background-color: #006a27;
}

.stable .buttons .button.red .b {
  background-color: #f64440;
}

.stable .label {
  width: 9.375rem;
  margin: 0 auto;
  height: 3.125rem;
  background-color: #ff5850;
  color: #fff;
  line-height: 3.125rem;
  text-align: center;
  font-size: 1.25rem;
  border-bottom: .5rem solid #6969e8;
  margin-top: 9.375rem;
}

/deep/.el-dialog {
  display: flex;
  flex-direction: column;
  margin: 0 !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: calc(100% - 1.875rem);
  max-width: calc(100% - 1.875rem);
  background: transparent;
  box-shadow: none;
  width: auto;
}



// 漂移时间 5.7</style> 
