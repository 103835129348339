<template>
  <el-dialog :visible="visible" :showClose="false" :modal="false" lock-scroll>
    <div data-v-3a918e3d="" class="imagecomp">
      <img src="../assets/img/zoomOut.png" class="zoomOut" @click="onClose" />
      <img
        :src="url"
        style="max-width: 33.25rem; width: 33.25rem"
      />
      
      
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    url:{
      type:String,
      default:''
    },
    isCheck:{
      type:Boolean,
      default:false
    },
    optionId:{
      type:[String,Number],
      default:""
    },
    close: {
      type: Function,
    },
  },
  data() {
    return {
      ansArr:[]
    };
  },
  methods: {
    onClose() {
      this.close && this.close();
    },
    toggleSelection(parmas) {
      let arr = this.ansArr.filter( x => x == parmas )
      if(arr.length){
        this.ansArr = this.ansArr.filter( x => x != parmas)
      }else{
        this.ansArr.push(parmas)
      }
      this.$emit('submit',this.ansArr)
    },
  },
};
</script>


<style lang="less" scoped>
// 屏幕
.el-dialog__wrapper {
  position: absolute;
}
/deep/.el-dialog {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  display: -ms-flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -ms-flex-direction: column;
  margin: 0 !important;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  max-height: calc(100% - 1.875rem);
  max-width: calc(100% - 1.875rem);
  background: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  width: auto;
}
/deep/.el-dialog .el-dialog__body {
  max-height: 100%;
  -webkit-box-flex: 1;
  flex: 1;
  -ms-flex: 1 1 auto;
  overflow-y: hidden;
  overflow-x: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: center;
}

.imagecomp {
  position: relative;
  border: 8px solid #fff;
  border-radius: 16px;
  background-color: #fff;
  .zoomOut {
    width: 2.8125rem;
    height: 2.8125rem;
    cursor: pointer;
    position: absolute;
    right: 1.25rem;
    top: 1.25rem;
    z-index: 999;
  }
  .list {
    .item {
      position: absolute;
      cursor: pointer;
      border: 3px solid green;
    }
    .selected {
      border: 3px solid orange;
      background-color: rgba(255, 165, 0, 0.4);
    }
  }
}
</style>
